import React, { useState } from "react";
import {
  StyledFooter,
  A,
  StyledA,
  StyledB,
  StyledBtn,
  StyledColumn,
  StyledColumn1,
  StyledContent,
  StyledH3,
  StyledInput,
  StyledInputRow,
  StyledLine,
  StyledLogo,
  StyledMain,
  StyledP,
  StyledP1,
  StyledROw,
  StyledText,
  StyledBtnA,
  IconRow,
  StyledIcon,
  StyledIcon2,
  StyledIcon3,
  StyledIcon4,
  StyledIcon1,
  StyledIcon5,
  StyledScroll,
  svgTwitter,
  svgInsta,
  svgLinkIn,
  svgFBook,
  svg,
  svgYoutube,
  StyledMainHeading1,
  StyledMainHeading2,
  StyledMainHeading3,
  StyledBelow,
  StyledScroll1,
  StyledIcon12,
  StyledIcon13,
  StyledIcon14,
  StyledScroll12,
  StyledP2,
  StyledP3,
  StyledInputItem,
  InputA,
  StyledButton,
} from "./style";
import ContactUs from "../ContactUs";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      // closeMobileMenu();
    }
  };
  return (
    <StyledFooter>
      <StyledContent>
        <StyledROw>
          <StyledA>
            <StyledLogo src="../footerlogo.png" alt="img"></StyledLogo>
            <StyledP>
              Liam Crest is committed to producing engaging, accessible, and
              high-quality video content that captivates audiences worldwide.
              Our company is built upon four core principles that drive us to
              deliver excellence in every project.
            </StyledP>
            {/* <StyledInputItem><InputA placeholder='Enter a topic'/> <StyledButton>Subscribe</StyledButton></StyledInputItem> */}
          </StyledA>
          <StyledB>
            <StyledColumn>
              <StyledMainHeading2> Quick Links</StyledMainHeading2>
              <StyledScroll onClick={() => scrollToSection("Home")}>
                Home
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("About us")}>
                About us
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("Services")}>
                Services
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("Our Work")}>
                Our Work
              </StyledScroll>
              <StyledScroll onClick={toggleModal}>Contact</StyledScroll>
            </StyledColumn>
            <StyledColumn1>
              <StyledColumn>
                <StyledMainHeading3>Quick Links</StyledMainHeading3>

                <StyledScroll1>
                  <StyledIcon13 src="../ph.png" alt="icon" /> 888 491 5293
                </StyledScroll1>
                <StyledScroll1>
                  <StyledIcon14 src="../ms.png" alt="icon" />{" "}
                  Sales@liamcrest.com
                </StyledScroll1>
                <StyledScroll1>
                  <StyledIcon12 src="../loc.png" alt="icon" /> Memphis, TN and
                  Phoenix, AZ
                </StyledScroll1>
                <IconRow>
                  <A href="https://www.instagram.com/liam.crest.corp/">
                    <StyledIcon>
                      <div dangerouslySetInnerHTML={{ __html: svgInsta }} />
                    </StyledIcon>
                  </A>
                  <A href="https://twitter.com/LiamCrestCorp">
                    <StyledIcon5>
                      <div dangerouslySetInnerHTML={{ __html: svgTwitter }} />
                    </StyledIcon5>
                  </A>
                  <A href="https://www.linkedin.com/company/liamcrest">
                    <StyledIcon1>
                      <div dangerouslySetInnerHTML={{ __html: svgLinkIn }} />
                    </StyledIcon1>
                  </A>
                  <A href="https://www.facebook.com/liamcrestcorp?_rdc=2&_rdr">
                    <StyledIcon2>
                      <div dangerouslySetInnerHTML={{ __html: svgFBook }} />
                    </StyledIcon2>
                  </A>
                </IconRow>
              </StyledColumn>
            </StyledColumn1>
            {isModalOpen && <ContactUs onClose={toggleModal} />}
          </StyledB>
        </StyledROw>
        <StyledBelow>
          <StyledText>
            <StyledP1>© 2024 Liam Crest.</StyledP1>
            <StyledP2>All Rights Reserved.</StyledP2>
            <StyledP2>Term & Condition</StyledP2>
            <StyledLine>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="20"
                viewBox="0 0 2 20"
                fill="none"
              >
                <path d="M1 0V20" stroke="#717B85" />
              </svg>
            </StyledLine>
            <StyledP3>Privacy & Policy</StyledP3>
          </StyledText>
        </StyledBelow>
      </StyledContent>
    </StyledFooter>
  );
};

export default Footer;

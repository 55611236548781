import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #0a1219;
  padding: 3% 0;
  box-sizing: border-box;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    max-height: 676px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    max-height: 776px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    max-height: 576px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    max-height: 976px;
    flex-direction: column;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    max-height: 780px;
    flex-direction: column;
  }
`;
export const StyledSectionA = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 45%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 45%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 45%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
  }
`;
export const StyledP = styled.p`
  color: var(--G2, #1ebb90);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;
export const StyledP1 = styled.p`
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  margin: 0;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 24px;
  }
`;
export const StyledP2 = styled.p`
  color: #e9eef1;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
  margin: 0.1rem;
  & span {
    font-weight: 600;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
    width: 85%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
  }
`;
export const StyledFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    justify-content: space-between;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    justify-content: space-between;
  }
`;
export const StyledFlex1 = styled.div`
  display: flex;
  width: 262px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 200px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 50%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 50%;
  }
`;
export const StyledP3 = styled.p`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline 6 */
  font-family: "DM Serif Display", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 121.429% */
  letter-spacing: -0.6px;
  margin: 0.1rem;
`;
export const StyledButton = styled.button`
  display: flex;
  width: 130px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: var(--G2, #1ebb90);
  color: var(--Neutural-White, #fff);
  text-align: center;
  cursor:pointer;
  /* Subhead 3 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-top: 2%;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
  }
`;
export const StyledSectionB = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 45%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 45%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 45%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
    justify-content: space-between;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    justify-content: space-between;
  }
`;
export const StyledImg = styled.img`
  width: 248.878px;
  height: 335px;
  flex-shrink: 0;
  border-radius: 16px;
  //   background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 184px;
    height: 247px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 166.851px;
    height: 223.98px;
    border-radius: 14.509px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 241.935px;
    height: 324.771px;
    border-radius: 21.038px;
  }
  @media only screen and (min-width: 500px) and (max-width: 640px) {
    width: 212.935px;
    height: 280.771px;
    border-radius: 21.038px;
  }
  @media only screen and (min-width: 420px) and (max-width: 499px) {
    width: 189.851px;
    height: 249.98px;
    border-radius: 14.509px;
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    width: 133.481px;
    height: 179.184px;
    border-radius: 11.607px;
  }
`;
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 13%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: 13%;
  }
`;
export const Ellipse = styled.div`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  background: #1ebb90;
  border-radius: 50%;
  margin-bottom: 15%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-bottom: 3%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 72.544px;
    height: 72.544px;
    margin-bottom: 3%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 79px;
    height: 79px;
    margin-bottom: 3%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 43.586px;
    height: 43.586px;
    margin-bottom: 3%;
  }
`;

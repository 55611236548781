import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutSection from "./Components/AboutSection";
import VideoSolution from "./Components/VideoSolution";
import ServicesSection from "./Components/ServicesSection";
import Main from "./Components/Main";
import ContactUs from "./Components/ContactUs";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/aboutsection" element={<AboutSection />} />
          <Route path="/videosolution" element={<VideoSolution />} />
          <Route path="/servicessection" element={<ServicesSection />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import styled from "styled-components";

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  background: #0a1219;
  overflow: hidden;
  padding-top: 5%;
  justify-content: center;
`;
export const TextHeading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
`;
export const TitleHeading = styled.p`
  color: var(--G2, #1ebb90);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transform: translateY(30px);
`;
export const MainHeading = styled.h2`
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transform: translateY(-5px);
`;
export const ParaHeading = styled.p`
  color: #e9eef1;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 70%;
  transform: translateY(-30px);
`;

export const StyledCardItem = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  @media only screen and (min-width: 1281px) and (max-width: 1436px) {
    width: 100%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 40px;
    width: 97%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 440px) {
    width: 100%;
  }
`;
export const StyledVideoCard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
  }
  @media only screen and (min-width: 320px) and (max-width: 490px) {
  }
`;
export const StyledVideo = styled.video`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  height: 80vh;
   margin-left:10%;
  border-radius: 24px;
  &:hover {
    transition: ease-in 0.9s;
    cursor: pointer;
  }

  @media only screen and (min-width: 1541px) and (max-width: 1880px) {
   
    height: 80vh;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 80%;
    height: 281.368px;
    border-radius: 15.964px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1080px) {
    width: 80%;
    height: 281.368px;
    border-radius: 15.964px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    margin-left:5%;
    height: auto;
    border-radius: 8.964px;
  }
`;
export const Overlay = styled.div`
  position: absolute;
  margin-top: -9%;
  margin-left: 6%;
  cursor: pointer;
  display: ${(props) => (props.isHidden ? "block" : "none")};
  &:hover {
  }
  @media only screen and (min-width: 2001px) and (max-width: 2500px) {
    margin-top: -30%;
    margin-left: 20.5%;
  }
  @media only screen and (min-width: 1537px) and (max-width: 2000px) {
    margin-top: -50%;
    margin-left: 35.5%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    margin-top: -50%;
    margin-left: 35.5%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-top: -38%;
    margin-left: 25.5%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: -50%;
    margin-left: 30.5%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: -60%;
    margin-left: 30.5%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: -52%;
    margin-left: 30.5%;
  }
`;

export const PlayButton = styled.div`
  position: relative;
  top: -55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: ${(props) => (props.isHidden ? "none" : "block")};
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-left: 20px solid rgba(22, 32, 93, 1);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    color: rgba(22, 32, 93, 1);
  }

  &:hover: {
    background-color: rgba(0, 0, 0, 0.7);
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    top: -58%;
    left: 50%;
    width: 50px; /* Adjust width as needed */
    height: 50px;
  }
`;

export const PauseButton = styled.div`
  position: relative;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: ${(props) => (props.isHidden ? "none" : "block")};

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 30%;
    width: 15%; /* Adjust width as needed */
    height: 40%;
    background: rgba(22, 32, 93, 1);
  }

  &::before {
    left: 30%;
  }

  &::after {
    left: 55%;
  }
  &:hover {
    background-color: #ffb210;
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    top: -58%;
    left: 50%;
    width: 50px; /* Adjust width as needed */
    height: 50px;
  }
`;
export const StyledCarosuel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 85%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 77%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 91%;
  }
`;
export const SVGContainer = styled.div`
  position: relative;
`;
export const StyledTextSection = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
  margin-left: 2%;
  height: 250px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: auto;
  }
`;
export const VideoText = styled.p`
  color: #fff;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  transition: ease-in 0.9s;
  width: 85%;
  cursor: pointer;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 8.69px;
  }
`;
export const StyledLine = styled.div`
  background: var(--Secondary-500, #1ebb90);
  height: 7px;
  align-self: stretch;
  width: 411px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 293.34px;
    height: 4.66px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 293.34px;
    height: 4.66px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 293.34px;
    height: 4.66px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 159.34px;
    height: 2.66px;
  }
`;
export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: 4%;
  }
`;
export const Button = styled.button`
  width: 215px;
  padding: 12px 16px;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid var(--G2, #1ebb90);
  color: #fff;
  text-align: center;
  background: transparent;
  /* Subhead 3 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
  &:hover {
    background: #fd9400;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    width: 115px;
    padding: 8px 16px;
  }
`;
export const Data1 = styled.div``;
export const Data2 = styled.div``;
export const VideoPara = styled.p`
  display: none;
  width: 70%;
  color: #e9eef1;
  text-align: left;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  transition: ease-in 0.9s;
  cursor: pointer;

  transition: opacity 0.5s ease;

  &.hover {
    display: block;
    opacity: 1;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 8px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 7px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 7px;
  }
`;
export const HoverTrigger = styled.div`
  &:hover ${VideoPara} {
    display: block;
    width: 80%;
    transition: ease-in 0.9s;
    cursor: pointer;
  }
`;

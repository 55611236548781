import styled from "styled-components";

export const StyledInfo=styled.div`

`;
export const HeaderLinks = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;

  justify-content: center;
  padding: 40px;
  @media screen and (max-width: 810px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 0.9;
    transition: all 0.5s ease;
    background: #101522;
    z-index:2;
    padding:0;
  }
`;
export const MobileIcon=styled.div`
 display: none;

@media screen and (max-width: 810px) {
  display: block;
  position: absolute;
  top: 18px;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  filter: brightness(1.2);
  z-index: 1;

}

`;
export const Navigation = styled.nav`
  display: flex;
justify-content: space-between;
  align-items: center;
  width: 97%;
  position: absolute;
  top:10px;
  @media only screen and (min-width:811px) and (max-width:1080px){
    width:95%
  }
  @media only screen and (min-width:320px) and (max-width:810px){
    flex-direction: column;
    gap:35px;
   top:30%;
  }
`;
export const StyledLogo=styled.img`
display:none;
@media only screen and (min-width:641px) and (max-width:810px){
  display:flex;
align-items:center;
justify-content: center;
padding:10px;
z-index:1;
position:absolute;
top:0;
width: 52px; 
height:40px;
left:4%;
margin-top:2%;
}
@media only screen and (min-width:200px) and (max-width:640px){
  display:flex;
align-items:center;
justify-content: center;
z-index:1;
position:absolute;
top:0;
width: 52px; 
height:40px;
padding:20px;
width: 52px; 
height:40px;
left:4%;
margin-top:2%;
}
`;

export const Logo = styled.img`
  width: 110px;
height: 80px;
flex-shrink: 0;
display:flex;
align-items: left;
justify-content: start;
position: relative;
z-index:1;
left:2%;
margin-top:1%;
@media only screen and (min-width:1281px) and (max-width:1536px){
  width: 100px; 
height:74px;
  
}
@media only screen and (min-width:1081px) and (max-width:1280px){
   width:94.37px;
   height: 66.31px;
   }
   @media only screen and (min-width:811px) and (max-width:1080px){
    width:70.69px;
   height: 49.67px;
  }
  @media only screen and (min-width:111px) and (max-width:810px){
    display:none;
  }
`;
export const LinkTop = styled.div`
 display:flex;
  align-items: end;
  justify-content: flex-end;
  gap:40px;
margin-right:2%;
margin-top: 2%;
@media only screen and (min-width: 1281px) and (max-width:1536px){
  margin-right:4%;
}
@media only screen and (min-width:320px) and (max-width:810px){
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
  `;

export const Link = styled.a`
     position: relative;
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  filter: brightness(1.2);
  text-decoration: none;
  display: inline-block;
  padding:10px 0;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 10px; 
    height: 10px; 
    background-color: #1EBB90;
    border-radius: 50%;
    transition: width 0.9s ease, height 0.9s ease;
    z-index: -1;
    transform: scaleX(0);
  }

  &:hover:after {
    display:flex;
    transform: translateX(-50%);
    transition: width 0.9s ease, height 0.9s ease;
    width: 40px; 
    height: 40px; 
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:14px;
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    font-size:14px;
  }

`;
export const ButtonStyle=styled.div`
display: flex;
justify-content: center;
align-items: center;
transform: translateY(4px);
@media only screen and (min-width:641px) and (max-width:810px){
  display:none;
}
@media only screen and (min-width:320px) and (max-width:640px){
display:flex;
}
`;
export const Button=styled.button`

width: 130px;
padding: 10px 16px;

border-radius: 12px;
background: var(--G2, #1EBB90);
color: var(--Neutural-White, #FFF);
text-align: center;
cursor: pointer;
border:none;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
&:hover{
    background: #FD9400;
}
@media only screen and (min-width:641px) and (max-width:810px){
  display:none;
}
@media only screen and (min-width:320px) and (max-width:640px){

}
`;
export const ButtonStyle1=styled.div`
display:none;

@media only screen and (min-width:641px) and (max-width:810px){
display: flex;
position:absolute;
top:3%;
left:63%;
}
@media only screen and (min-width:470px) and (max-width:640px){
  display:none;
}
@media only screen and (min-width:320px) and (max-width:469px){
display: none;
}
`;
export const Button1=styled.button`

display:none;
@media only screen and (min-width:641px) and (max-width:810px){
  display:flex;
  width: 130px;
padding: 10px 16px;
border-radius: 12px;
background: var(--G2, #1EBB90);
color: var(--Neutural-White, #FFF);
text-align: center;
cursor: pointer;
border:none;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
&:hover{
    background: #FD9400;
}
}
@media only screen and (min-width:320px) and (max-width:640px){
  display:none;
}
`;
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import VideoTestimonial from "./VideoTestimonial";
import {
    StyledInfo,
    StyledText,
    HeadingTitle,
    StyledMainContainer,
    StyledCard,
    StyledA,
    StyledTextCard,
    StyledIcons,
    StyledPara,
    // StyledItemText,
    // TitleName,
    // LogoImage,
    StyledAllItems
} from './style.js';

const TestimonialSec = () => {
  const videoSrc = "./Testimonial.mp4";

  return (
    <StyledInfo>
      <StyledText>
        <HeadingTitle>Client Feedback</HeadingTitle>
      </StyledText>
      <VideoTestimonial videoSrc={videoSrc} autoplay={true} />
    </StyledInfo>
  );
}

export default TestimonialSec
import styled from "styled-components";

export const BackgroundImage = styled.div`
  background: url('../bg.png') rgba(13, 22, 32, 0.8) ;
  width:100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  height:   100vh; 
   &::before {
    content: '';
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;

   
  }
  @media only screen and (min-width:320px) and (max-width:640px){
   
  }
`;
export const Section = styled.section`
  text-align: center;
 display:flex;
  align-items: center;
  justify-content: center;

`;
export const Section1=styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media only screen and (min-width:320px) and (max-width:640px){
    margin-top:11%;
  }
  `;
  export const Heading=styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:80vh;
  `;
export const BigHeading = styled.h1`
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    filter: brightness(2.2);
    transform: translateY(-10px);
    @media only screen and (min-width:1081px) and (max-width:1280px){
 
}
  @media only screen and (min-width:811px) and (max-width:1080px){
  font-size:40px;
  line-height:normal;
}
  @media only screen and (min-width:641px) and (max-width:810px){
  font-size: 32px;
  line-height:normal;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 24px;
  letter-spacing: -0.64px;
  line-height:normal;
  width: 70%;
}
`;
export const SmallHeading=styled.p`
color: var(--G2, #1EBB90);
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
filter: brightness(1);
transform: translateY(38px);
@media only screen and (min-width:1081px) and (max-width:1280px){
  letter-spacing:0.1px;
  font-size: 18px;

}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 16px;
  transform: translateY(0);
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 16px;
  transform: translateY(0);
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 14px;
transform: translateY(0);
  letter-spacing:0.4px;
}
`;
export const ParaHeading=styled.p`
    transform: translateY(-60px);
    color: #E9EEF1;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width:70%;
    @media only screen and (min-width:1081px) and (max-width:1280px){
  
  font-size: 14px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 14px;
  transform: translateY(-48px);
 
}

@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 14px;
  transform: translateY(-50px);
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 14px;

  transform: translateY(-32px);
}
`;
export const ButtonStyle=styled.div`
display: flex;
align-items: center;
justify-content: center;
transform: translateY(-40px);
@media only screen and (min-width:320px) and (max-width:640px){
  transform: translateY(-30px);
}
`;
export const Button=styled.button`
width: 215px;
padding: 12px 16px;
justify-content: center;
align-items: center;
border-radius: 12px;
border: 1px solid var(--G2, #1EBB90);
background: transparent;
color: #FFF;
text-align: center;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
cursor: pointer !important;
&:hover{
    background: #FD9400 !important;
}
`;

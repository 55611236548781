import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: 876px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #0a1219;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 776px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 876px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: auto;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: auto;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: auto;
  }
`;
export const StyledText = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 80%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 80%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
  }
`;
export const StyledP = styled.p`
  color: var(--G2, #1ebb90);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;
export const StyledP1 = styled.p`
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const StyledP2 = styled.p`
  width: 60%;
  color: #e9eef1;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 100%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 100%;
  }
`;
export const StyledContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
  }
`;
export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    flex-direction: column;
    width: 50%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    flex-direction: column;
    width: 50%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const StyledCard = styled.div`
  width: 30%;
  height: 250px;
  justify-content: center;
  padding-left: 15px;
  display: flex;
  //   padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  //   flex: 1 0 0;
  border-radius: 16px;
  background: linear-gradient(
    92deg,
    rgba(53, 97, 142, 0.1) 0%,
    rgba(30, 187, 144, 0.1) 100%
  );
  transition: background-color 0.5s ease, color 2.5s ease;
  &: hover {
    background: var(--G2, #1ebb90);
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 250px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 90%;
    height: 250px;
    margin-top: 2%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
    height: 250px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: 250px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
`;
export const StyledIcon = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: rgba(30, 187, 144, 1);
  transition: background-color 0.5s ease, color 2.5s ease;
  ${StyledCard}:hover & {
    color: #1a237e;
  }
`;
export const StyledH2 = styled.h2`
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: background-color 0.5s ease, color 2.5s ease;
  ${StyledCard}:hover & {
    color: #1a237e;
  }
`;
export const StyledP3 = styled.p`
  color: #e9eef1;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
  transition: background-color 0.5s ease, color 2.5s ease;
  ${StyledCard}:hover & {
    color: #1a237e;
  }
`;

import React, { useState } from "react";
import {
  ModalContent,
  ModalOverlay,
  CloseButton,
  VideoIframe,
  VideoContainer,
  StyledMain,
  Loader
} from "./style";

const VideoPopup = ({ onClose }) => {
  const [loading, setLoading] = useState(true);

  const handlePlaying = () => {
    setLoading(false);
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <StyledMain>
          <CloseButton onClick={onClose}>×</CloseButton>
          {loading && <Loader></Loader>}
          <VideoContainer>
            <VideoIframe  
              src="ALL_SCENE_2.mp4"
              title="Video Player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onPlaying={handlePlaying}
            ></VideoIframe>
          </VideoContainer>
        </StyledMain>
      </ModalContent>
    </ModalOverlay>
  );
};

export default VideoPopup;

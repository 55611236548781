import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: 876px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #0a1219;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 750px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 740px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 740px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: auto;
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;
export const StyledImgContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 42%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 80%;
    margin: 3%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
  }
`;
export const StyledImg = styled.img`
  width: 248.878px;
  height: 335px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-bottom: 30%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 235px;
    height: 335px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 185.704px;
    height: 265.778px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 164.173px;
    height: 234.962px;
    border-radius: 10.678px;
    margin-bottom: 60%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 218px;
    height: 312px;
    border-radius: 16px;
  }
  @media only screen and (min-width: 500px) and (max-width: 640px) {
    width: 200.935px;
    height: 270.771px;
    border-radius: 21.038px;
    margin-top: 5%;
  }
  @media only screen and (min-width: 420px) and (max-width: 499px) {
    width: 189.851px;
    height: 249.98px;
    border-radius: 14.509px;
    margin-top: 5%;
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    width: 133.481px;
    height: 179.184px;
    border-radius: 11.607px;
    margin-top: 5%;
  }
`;
export const StyledImg1 = styled.img`
  width: 248.878px;
  height: 335px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-top: 30%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 235px;
    height: 335px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 185.704px;
    height: 265.778px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 164.173px;
    height: 234.962px;
    border-radius: 10.678px;
    margin-top: 8%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 218px;
    height: 312px;
    border-radius: 16px;
  }
  @media only screen and (min-width: 500px) and (max-width: 640px) {
    width: 200.935px;
    height: 270.771px;
    border-radius: 21.038px;
    margin-top: 25%;
    margin-bottom: 6%;
  }
  @media only screen and (min-width: 420px) and (max-width: 499px) {
    width: 189.851px;
    height: 249.98px;
    border-radius: 14.509px;
    margin-top: 25%;
    margin-bottom: 6%;
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    width: 133.481px;
    height: 179.184px;
    border-radius: 11.607px;
    margin-top: 25%;
    margin-bottom: 6%;
  }
`;
export const StyledSolutionContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 45%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 45%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 90%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    margin-top: 10%;
  }
`;
export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2b2f34;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    padding: 11px 0px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    padding: 9px 0px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    padding: 9px 0px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    padding: 9px 0px;
  }
`;
export const StyledText = styled.p`
  color: #e7ecf3;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  ${StyledInput}:hover & {
    color: var(--G2, #1ebb90);
  }
`;
export const Styledtext1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const StyledP3 = styled.p`
  max-height: ${({ isVisible }) => (isVisible ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease; /* Added opacity transition */
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  color: #e9eef1;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
  margin: 0.1rem;
  & span {
    font-weight: 600;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
  }
`;
export const StyledIcon = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #fff;
  cursor: pointer;
  ${StyledInput}:hover & {
    color: var(--G2, #1ebb90);
  }
`;

import React, { useEffect, useRef, useState } from "react";
import {
  StyledInfo,
  TextHeading,
  TitleHeading,
  MainHeading,
  ParaHeading,
  StyledVideoCard,
  StyledCardItem,
  StyledVideo,
  Overlay,
  PauseButton,
  PlayButton,
  StyledTextSection,
  VideoText,
  StyledLine,
  StyledButton,
  Button,
  VideoPara,
  HoverTrigger,
} from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import VideoPopup from "../VideoPopup";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

const AboutVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(3); // Default slidesPerView
  const [overlayVisibility, setOverlayVisibility] = useState(
    Array(6).fill(false)
  );
  const videoRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1080) {
        setSlidesPerView(2);
      }
      if (screenWidth < 810) {
        setSlidesPerView(1);
      }
      if (screenWidth > 1081) {
        setSlidesPerView(3); // Default value
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial calculation on component mount
    handleResize();

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const togglePlayPause = (index) => {
    const video = videoRefs[index].current;

    if (video && (video.paused || video.ended)) {
      video.play();
      setIsPlaying(true);
    } else if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleMouseEnter = (index) => {
    const newOverlayVisibility = [...overlayVisibility];
    newOverlayVisibility[index] = true;
    setOverlayVisibility(newOverlayVisibility);
  };

  const handleMouseLeave = (index) => {
    const newOverlayVisibility = [...overlayVisibility];
    newOverlayVisibility[index] = false;
    setOverlayVisibility(newOverlayVisibility);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <StyledInfo id="Our Work">

        <StyledCardItem>
          {/* <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={20}
            freeMode={true}
            pagination={false}
            autoplay={{ delay: 2000 }}
            modules={[FreeMode, Pagination, Autoplay]}
            className="mySwiper"
          > */}
            {/* <SwiperSlide></SwiperSlide> */}
            <SwiperSlide>
              <StyledVideoCard>
                <HoverTrigger>
                  {" "}
                  <StyledVideo
                    ref={videoRefs[1]}
                    src="../Final Version 6.mp4"
                    controls
                    onPlay={handlePlay}
                    onPause={handlePause}
                    poster="../thumbnail-01.jpg"
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={() => handleMouseLeave(1)}
                  ></StyledVideo>
                  <Overlay
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={() => handleMouseLeave(1)}
                    isHidden={overlayVisibility[1]}
                    onClick={() => togglePlayPause(1)}
                  >
                    {/* {isPlaying ? <PauseButton /> : <PlayButton />} */}
                  </Overlay>
                </HoverTrigger>{" "}
              </StyledVideoCard>
            </SwiperSlide>
            {/* <SwiperSlide></SwiperSlide> */}

            {/* <SwiperSlide>
              <StyledVideoCard>
                <HoverTrigger>
                  {" "}
                  <StyledVideo
                    ref={videoRefs[3]}
                    src="../Allslides.mp4"
                    controls
                    onPlay={handlePlay}
                    onPause={handlePause}
                    poster="../poster.png"
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={() => handleMouseLeave(3)}
                  ></StyledVideo>
                  <Overlay
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={() => handleMouseLeave(3)}
                    isHidden={overlayVisibility[3]}
                    onClick={() => togglePlayPause(3)}
                  >
                    {isPlaying ? <PauseButton /> : <PlayButton />}
                  </Overlay>
                  <StyledTextSection>
                    <VideoText>Swan Mandes - You Better</VideoText>
                    <VideoPara>
                    Start by tapping into your real potential. 
                    Let's strategize an artistic idea and create a message that will 
                    surely reach your audience. We will do the heavy lifting. Just give us your vision.
                    <p style={{fontSize:'12px',display:'flex', color:'white',fontFamily:'Montserrat',fontStyle:'Bold'}}>Director : Liam Crest</p>
                    <p style={{fontSize:'12px', color:'white',fontFamily:'Montserrat',fontStyle:'Bold'}}>Production / Post-Production : Liam Crest Team</p>
                    </VideoPara>
                    <StyledLine></StyledLine>
                  </StyledTextSection>
                </HoverTrigger>
              </StyledVideoCard></SwiperSlide>
<SwiperSlide>
              <StyledVideoCard>
                <HoverTrigger>
                  {" "}
                  <StyledVideo
                    ref={videoRefs[4]}
                    src="../Graphicdesignpromotionaledits.mp4"
                    controls
                    onPlay={handlePlay}
                    onPause={handlePause}
                    poster="../poster1.png"
                    onMouseEnter={() => handleMouseEnter(4)}
                    onMouseLeave={() => handleMouseLeave(4)}
                  ></StyledVideo>
                  <Overlay
                    onMouseEnter={() => handleMouseEnter(4)}
                    onMouseLeave={() => handleMouseLeave(4)}
                    isHidden={overlayVisibility[4]}
                    onClick={() => togglePlayPause(4)}
                  >
                    {isPlaying ? <PauseButton /> : <PlayButton />}
                  </Overlay>
                  <StyledTextSection>
                    <VideoText>Swan Mandes - You Better</VideoText>
                    <VideoPara>
                    Start by tapping into your real potential. 
                    Let's strategize an artistic idea and create a message that will 
                    surely reach your audience. We will do the heavy lifting. Just give us your vision.
                    <p style={{fontSize:'12px',display:'flex', color:'white',fontFamily:'Montserrat',fontStyle:'Bold'}}>Director : Liam Crest</p>
                    <p style={{fontSize:'12px', color:'white',fontFamily:'Montserrat',fontStyle:'Bold'}}>Production / Post-Production : Liam Crest Team</p>
                    </VideoPara>
                    <StyledLine></StyledLine>
                  </StyledTextSection>
                </HoverTrigger>{" "}
              </StyledVideoCard>
              </SwiperSlide>
              <SwiperSlide>
              <StyledVideoCard>
                <HoverTrigger>
                  {" "}
                  <StyledVideo
                    ref={videoRefs[5]}
                    src="../Solarsystemfinal.mp4"
                    controls
                    onPlay={handlePlay}
                    onPause={handlePause}
                    poster="../poster3.png"
                    onMouseEnter={() => handleMouseEnter(5)}
                    onMouseLeave={() => handleMouseLeave(5)}
                  ></StyledVideo>
                  <Overlay
                    onMouseEnter={() => handleMouseEnter(5)}
                    onMouseLeave={() => handleMouseLeave(5)}
                    isHidden={overlayVisibility[5]}
                    onClick={() => togglePlayPause(5)}
                  >
                    {isPlaying ? <PauseButton /> : <PlayButton />}
                  </Overlay>
                  <StyledTextSection>
                    <VideoText>Swan Mandes - You Better</VideoText>
                    <VideoPara>
                    Start by tapping into your real potential. 
                    Let's strategize an artistic idea and create a message that will 
                    surely reach your audience. We will do the heavy lifting. Just give us your vision.
                    <p style={{fontSize:'12px',display:'flex', color:'white',fontFamily:'Montserrat',fontStyle:'Bold'}}>Director : Liam Crest</p>
                    <p style={{fontSize:'12px', color:'white',fontFamily:'Montserrat',fontStyle:'Bold'}}>Production / Post-Production : Liam Crest Team</p>
                    </VideoPara>
                    <StyledLine></StyledLine>
                  </StyledTextSection>
                </HoverTrigger>{" "}
              </StyledVideoCard>
              </SwiperSlide> */}
          {/* </Swiper> */}
        </StyledCardItem>
      </StyledInfo>
      {isModalOpen && <VideoPopup onClose={toggleModal} />}
    </>
  );
};

export default AboutVideo;

import styled from "styled-components"

export const StyledCollaborate=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction:row;
height:auto;
background: #0A1219;

@media only screen and (min-width:811px) and (max-width:1080px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
@media only screen and (min-width:641px) and (max-width:810px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:auto;
}
@media only screen and (min-width:320px) and (max-width:640px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:auto
    ;
}
`;
export const StyledSection=styled.div`
display:flex;
padding:80px;
@media only screen and (min-width:811px) and (max-width:1080px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
@media only screen and (min-width:641px) and (max-width:810px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
@media only screen and (min-width:320px) and (max-width:640px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding:10px;
}
`;
export const StyledText=styled.div`
display:flex;

justify-content: center;
flex-direction: column;
text-align: left;
@media only screen and (min-width:811px) and (max-width:1080px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align:center;
}
@media only screen and (min-width:641px) and (max-width:810px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align:center;
}
@media only screen and (min-width:320px) and (max-width:640px){
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align:center;
}

`;
export const HeadingTitle=styled.p`
color: var(--G2, #1EBB90);
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align:left;
@media only screen and (min-width:811px) and (max-width:1080px){
    text-align:center;
}
@media only screen and (min-width:641px) and (max-width:810px){
    text-align:center;
}
@media only screen and (min-width:320px) and (max-width:640px){
    text-align:center;
}
`;
export const HeadingPara=styled.h2`
color: #FFF;
font-family: Montserrat;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
width:80%;
text-align: left;
@media only screen and (min-width:811px) and (max-width:1080px){
    text-align:center;
}
@media only screen and (min-width:641px) and (max-width:810px){
    text-align:center;
}
@media only screen and (min-width:320px) and (max-width:640px){
    text-align:center;
}
`;
export const StyledIcon=styled.div`
transform:translateX(-20%);
@media only screen and (min-width:1281px) and (max-width:1536px){
    
}
@media only screen and (min-width:280px) and (max-width:1280px){
    display:none;
}
`;
export const StyledInput=styled.div`
display: flex;
flex-direction:column;
align-items: flex-start;
justify-content: center;
gap:10px;
@media only screen and (min-width:811px) and (max-width:1080px){
 align-items:center;
 }
 @media only screen and (min-width:641px) and (max-width:810px){
 align-items:center;
 }
 @media only screen and (min-width:320px) and (max-width:640px){
 align-items:center;
 }
`;
export const StyledDisplay=styled.div`
display: flex;
flex-direction: row;
gap:10px;
@media only screen and (min-width:320px) and (max-width:640px){
    flex-direction:column;
}
`;

export const InputData1=styled.input`
display: flex;
width:252.79px;
height:64px;
font-family: Montserrat;
align-items: center;
border:none;
align-self: stretch;
border-radius: 16px;
background: #0D1821;
font-size: 16px;
padding-left:10px;
color: #96A1AB;
cursor:pointer;
&::placeholder{
color: #96A1AB;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */

}
@media only screen and (min-width:320px) and (max-width:640px){
 width:288px;
 height:64px;   
}
`;
export const InputData2=styled.input`
display: flex;
width:252.79px;
font-family: Montserrat;
height:64px;
align-items: center;
align-self: stretch;
border-radius: 16px;
background: #0D1821;
font-size: 16px;
cursor:pointer;
padding-left:10px;
color: #96A1AB;
border:none;
&::placeholder{
    color: #96A1AB;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */

}
@media only screen and (min-width:320px) and (max-width:640px){
 width:288px;
 height:64px;   
}
`;
export const InputData3=styled.input`
display: flex;
width:529.79px;
height:64px;
font-family: Montserrat;
font-size: 16px;
align-items: center;
align-self: stretch;
border-radius: 16px;
background: #0D1821;
cursor:pointer;
border:none;
padding-left:10px;
color: #96A1AB;
&::placeholder{

    color: #96A1AB;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */

}
@media only screen and (min-width:320px) and (max-width:640px){
 width:288px;
 height:64px;   
}
`;
export const StyledButton=styled.div`
display: flex;
justify-content: center;
align-items: center;
float:left;
align-items: left;
margin-top: 2%;
@media only screen and (min-width:811px) and (max-width:1080px){
  align-items: center;
}
@media only screen and (min-width:641px) and (max-width:810px){
  align-items: center;
}
@media only screen and (min-width:320px) and (max-width:640px){
  align-items: center;
}
`;
export const Button=styled.button`
display: flex;
border-radius: 16px;
background: var(--G2, #1EBB90);
padding: 16px;
border:none;
color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor:pointer;
&:hover{
    background: #FD9400;
}
`;
import styled from "styled-components";

export const StyledProduction = styled.div`
  background: url("../bg1.png") rgba(13, 22, 32, 0.8);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  height:671px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  height:671px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  height:1045px;
  justify-content: center;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
  height:auto;
  justify-content: center;
  }
  
  @media only screen and (min-width: 2px) and (max-width: 810px) {
    display: none;
  }
`;

export const StyledText = styled.div`
  margin-top: 2%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  margin-top:2%;
  }
`;

export const HeadingTitle = styled.p`
  color: #ffb210;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const HeadingPara = styled.h2`
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transform: translateY(-10px);
`;
export const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @media only screen and (min-width: 1537px) and (max-width: 1600px) {
    gap:20px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    gap: 14px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto auto;
    gap: 17px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto auto;
    gap: 17px;
  }
`;
export const CardData = styled.div`
  width: 210.833px;
  height: 379px;
  flex-shrink: 0;
  border-radius: 9.664px;
  background: var(
    --g3,
    linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%),
    linear-gradient(139deg, #35618e 25.33%, #1ebb90 76.39%)
  );
  position: relative;
  overflow: hidden;
  @media only screen and (min-width: 1537px) and (max-width: 1600px) {
    width: 225px;
    height: 389px;
  }
  @media only screen and (min-width: 1500px) and (max-width: 1536px) {
    width: 219.431px;
    height: 389px;
  }
  @media only screen and (min-width: 1405px) and (max-width: 1499px) {
    width: 214.431px;
    height: 389px;
  }
  @media only screen and (min-width: 1316px) and (max-width: 1404px) {
    width: 205.431px;
    height: 389px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1315px) {
    width: 185.431px;
    height: 379px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 210.833px;
    height: 379px;
  }
`;
export const TextData = styled.h2`
  color: var(--White, #fff);
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transform: translateY(18px);
  width: 60%;
  text-align: left;
  margin-left: 10px;
`;
export const Logo = styled.div`
  display: flex;
  width: 293.967px;
  height: 200px;
  margin-top: 61%;
  @media only screen and (min-width: 1537px) and (max-width: 1600px) {
    width:320px;
    height:210px;
 
  }
  @media only screen and (min-width: 1500px) and (max-width: 1536px) {
    margin-top: 69%;
    width:320px;
    height:210px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1499px) {
    margin-top: 69%;
    
  }
`;
export const Logo2 = styled.div`
  display: flex;
  width: 293.967px;
  height: 200px;
  margin-top: 62%;
  @media only screen and (min-width: 1500px) and (max-width: 1536px) {
    margin-top: 65%;
  }
  @media only screen and (min-width: 1406px) and (max-width: 1499px) {
    margin-top: 67%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1405px) {
    margin-top: 70%;
  }
`;
export const Logo1 = styled.div`
  display: flex;
  width: 520.583px;
  height: 196.383px;
  transform: rotate(3.822deg);
  margin-top: 79%;
  margin-left: 1%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    margin-top: 85%;
  }
`;
export const Logo3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 213.967px;
  height: 200px;
  margin-top: 1%;
  @media only screen and (min-width: 1537px) and (max-width: 1600px) {
    width:221px;
  }
  @media only screen and (min-width:1500px) and (max-width:1536px){
    width:221px;
  }
`;
export const Logo5 = styled.div`
  display: flex;
  flex-direction: column;
  width: 213.967px;
  height: 200px;
  margin-top: 59%;
  @media only screen and (min-width: 1537px) and (max-width: 1600px) {
    width:228px;
  }
  @media only screen and (min-width: 1500px) and (max-width: 1536px) {
    margin-top: 61%;
    width:220px;
  }
  @media only screen and (min-width: 1406px) and (max-width: 1499px) {
    margin-top: 65%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1405px) {
    margin-top: 67%;
  }
`;
export const SecondLogo = styled.img``;
export const Icon = styled.div`
  margin-top: -40%;
  display: flex;
  float: right;
  transform: translateX(-20%);
  @media only screen and (min-width:1500px) and (max-width:1536px){
    margin-top:-51%;
  }
  @media only screen and (min-width:1405px) and (max-width:1499px){
    margin-top:-45%;
  }
`;
export const Icon1 = styled.div`
  margin-top: -55%;
  display: flex;
  float: right;
  transform: translateX(-20%);
  @media only screen and (min-width:1405px) and (max-width:1536px){
    margin-top:-59%;
  }
`;
export const Icon3 = styled.div`
  margin-top: 12%;
  display: flex;
  float: right;
  transform: translateX(-20%);
  @media only screen and (min-width:1405px) and (max-width:1536px){
    margin-top:27%;
  }
  @media only screen and (min-width:1316px) and (max-width:1404px){
    margin-top:18%;
  }
`;
export const Icon4 = styled.div`
  margin-top: -25%;
  display: flex;
  float: right;
  transform: translateX(-20%);
  @media only screen and (min-width:1405px) and (max-width:1536px){
    margin-top:-29%;
  }
`;
export const Icon5 = styled.div`
  margin-top: -37%;
  display: flex;
  float: right;
  transform: translateX(-20%);
  @media only screen and (min-width:1405px) and (max-width:1536px){
    margin-top:-41%;
  }
`;

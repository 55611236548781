import React, { useState } from "react";
import {
  Ellipse,
  StyledButton,
  StyledDiv,
  StyledFlex,
  StyledFlex1,
  StyledImg,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP2,
  StyledP3,
  StyledSectionA,
  StyledSectionB,
} from "./styles";
import VideoPopup from "../VideoPopup";
import AboutVideo from "./AboutVideo";
function AboutSection() {
  const videoSrc = "./Testimonial.mp4";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <StyledMain>
      <StyledSectionA>
        <StyledP>About Liam Crest</StyledP>
        <StyledP1>What Sets LIAM CREST Apart From Everyone Else</StyledP1>
        <StyledP2>
          Our team has over 20 years of filming and videography experience. We
          encourage clients to have fun with their ideas while giving them
          various options to use. We can write scripts, storyboard scenarios,
          conduct casting calls, film, and edit all material.{" "}
        </StyledP2>
        <StyledFlex>
          <StyledFlex1>
            <StyledP3>10+</StyledP3>
            <StyledP2>
              <span>Years of experience</span>
            </StyledP2>
            <StyledP2>
              We prioritize fostering strong relationships with our clients.
            </StyledP2>
          </StyledFlex1>
          <StyledFlex1>
            <StyledP3>5+</StyledP3>
            <StyledP2>
              <span>Awards won</span>
            </StyledP2>
            <StyledP2>
              We prioritize fostering strong relationships with our clients.
            </StyledP2>
          </StyledFlex1>
        </StyledFlex>
        {/* <StyledButton>Learn more</StyledButton> */}
      </StyledSectionA>
      <StyledSectionB>
        <StyledImg src="/img101.png" alt="img"></StyledImg>
        <StyledDiv>
          <Ellipse></Ellipse>
          <StyledImg src="/img.png" alt="img"></StyledImg>
        </StyledDiv>
        {/* <AboutVideo videoSrc={videoSrc} autoplay={true} /> */}
      </StyledSectionB>
    </StyledMain>
  );
}

export default AboutSection;

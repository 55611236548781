import React, { useState } from "react";
import {
  ModalContent,
  ModalOverlay,
  StyledButton,
  StyledDiv,
  StyledEllipse,
  StyledEllipse1,
  StyledEllipse2,
  StyledH2,
  StyledImg,
  StyledImg1,
  StyledImg2,
  StyledInput,
  StyledInput1,
  StyledInputFlex,
  StyledMain,
} from "./styles";
import axios from "axios";
const ContactUs = ({ onClose }) => {
  const [formData, setFormData] = useState({
    website: "Production",
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState(null);
  const [statusColor, setStatusColor] = useState("black");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

   const validateEmail = (email) => {
     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     return re.test(String(email).toLowerCase());
   };

    const resetForm = () => {
      setFormData({
        website: "Production",
        name: "",
        email: "",
        message: "",
      });
    };

  const handleSend = async () => {
  //  alert("sa");
   if (!formData.name || !formData.email || !formData.message) {
     setStatus("Please fill in all fields.");
     setStatusColor("red");
     return;
   }

   if (!validateEmail(formData.email)) {
     setStatus("Please enter a valid email address.");
     setStatusColor("red");
     return;
   }

   try {
     const response = await axios.post(
       "https://lmsbackend.liamcrest.com/api/register_query",
       formData,
       {
         headers: {
           "Content-Type": "application/json",
           "Access-Control-Allow-Origin": "*",
         },
       }
     );

     if (response.status === 200) {
       setStatus("Form submitted successfully!");
       setStatusColor("green");
       resetForm(); // Reset form data after successful submission
     } else {
       setStatus("Form submission failed.");
       setStatusColor("red");
     }
   } catch (error) {
     setStatus("Error sending form data: " + error.message);
     setStatusColor("red");
   }
 };
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <StyledMain>
          <StyledH2>Contact Us</StyledH2>
          <StyledDiv>
            <StyledEllipse></StyledEllipse>
            <StyledImg src="/Ellipse1.png" alt="img"></StyledImg>
            <StyledEllipse1></StyledEllipse1>
          </StyledDiv>
          <StyledDiv>
            <StyledImg1 src="/Ellipse3.png" alt="img"></StyledImg1>
            <StyledEllipse2></StyledEllipse2>
            <StyledImg2 src="/Ellipse2.png" alt="img"></StyledImg2>
          </StyledDiv>
          <StyledInputFlex>
            <StyledInput
              type="text"
              id="name"
              placeholder="Enter your name"
              onChange={handleChange}
              value={formData.name}
            ></StyledInput>
            <StyledInput
              type="email"
              id="email"
              placeholder="Enter your email"
              onChange={handleChange}
              value={formData.email}
            ></StyledInput>
          </StyledInputFlex>
          <StyledInput1
            rows={4}
            id="message"
            placeholder="Enter a topic"
            onChange={handleChange}
            value={formData.message}
          ></StyledInput1>
          <StyledInputFlex>
            <StyledButton onClick={handleSend}>Send Message</StyledButton>

            <StyledButton onClick={onClose}>Cancel</StyledButton>
          </StyledInputFlex>
          {status && (
            <h4
              style={{
                marginTop: "10px",
                color: statusColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {status}
            </h4>
          )}
        </StyledMain>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ContactUs;

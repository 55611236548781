import styled from "styled-components";

export const StyledMain = styled.div`
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  background: #0a1219;
  height: 520px;
  width: 100%;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    padding: 15px;
  }
`;
export const StyledH2 = styled.h2`
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 75% */
`;
export const StyledDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
export const StyledEllipse = styled.div`
  width: 15.029px;
  height: 15.029px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #fac632;
`;
export const StyledEllipse1 = styled.div`
  width: 30.735px;
  height: 30.735px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #1ebb90;
`;
export const StyledEllipse2 = styled.div`
  width: 30.735px;
  height: 30.735px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #fac632;
`;
export const StyledImg = styled.img`
  width: 88.529px;
  height: 88.529px;
  flex-shrink: 0;
  border-radius: 128.529px;
`;
export const StyledImg1 = styled.img`
  width: 103.706px;
  height: 103.706px;
  flex-shrink: 0;
  border-radius: 163.706px;
`;
export const StyledImg2 = styled.img`
  width: 96.794px;
  height: 96.794px;
  flex-shrink: 0;
  border-radius: 146.794px;
`;
export const StyledInputFlex = styled.div`
  width: 96%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const StyledInput = styled.input`
  display: flex;
  padding: 0 5%;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 46%;
  height: 55px;
  border-radius: 16px;
  background: #0d1821;
  color: #96a1ab;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border: none;
`;
export const StyledInput1 = styled.textarea`
  padding: 2% 6%;
  align-self: stretch;
  border-radius: 16px;
  background: #0d1821;
  color: #96a1ab;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border: none;
`;
export const StyledButton = styled.button`
  display: flex;
  //   padding: 16px;
  width: 45%;
  height: 55px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  //   flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid var(--G2, #1ebb90);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #0a1219;
  &: hover {
    background: var(--G2, #1ebb90);
  }
`;
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure modal appears above other content */
  backdrop-filter: blur(17px);
`;

export const ModalContent = styled.div`
  //   background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  //   overflow-y: auto; /* Enable scrolling for modal content */
`;

import React, { useState } from "react";
import {
  StyledInfo,
  HeaderLinks,
  Navigation,
  Logo,
  Link,
  MobileIcon,
  LinkTop,
  StyledLogo,
  ButtonStyle,
  Button,
  ButtonStyle1,
  Button1,
} from "./style";

import { IconContext } from "react-icons/lib";
import { FaBars, FaTimes } from "react-icons/fa";
import ContactUs from "../ContactUs";

const HeaderTop = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      closeMobileMenu();
    }
  };
  return (
    <IconContext.Provider value={{ color: "FFFFFF" }}>
      <StyledInfo>
        <StyledLogo src="../logo.png" alt="Logo" />

        <MobileIcon onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </MobileIcon>

        <HeaderLinks onClick={handleClick} click={click}>
          <Navigation>
            <Logo src="../logo.png" alt="Logo" />
            <LinkTop>
              {" "}
              <Link
                onClick={() => scrollToSection("Home")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Link>
              <Link
                onClick={() => scrollToSection("About us")}
                style={{ cursor: "pointer" }}
              >
                About us
              </Link>
              <Link
                onClick={() => scrollToSection("Services")}
                style={{ cursor: "pointer" }}
              >
                Services
              </Link>
              <Link
                onClick={() => scrollToSection("Our Work")}
                style={{ cursor: "pointer" }}
              >
                Our Work
              </Link>
              <Link onClick={toggleModal} style={{ cursor: "pointer" }}>
                Contact
              </Link>
              {/* <ButtonStyle> <Button>Gets Quote</Button></ButtonStyle> */}
            </LinkTop>
          </Navigation>
        </HeaderLinks>
        {/* <ButtonStyle1>  <Button1>Gets Quote</Button1>
          </ButtonStyle1> */}
      </StyledInfo>
      {isModalOpen && <ContactUs onClose={toggleModal} />}
    </IconContext.Provider>
  );
};

export default HeaderTop;

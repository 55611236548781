import React, { useState } from "react";
import {
  BackgroundImage,
  ButtonStyle,
  Button,
  Section1,
  BigHeading,
  SmallHeading,
  Heading,
  ParaHeading,
} from "./style";
import Header from "../Header";
import VideoPopup  from "../VideoPopup";

const Index = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <BackgroundImage id="Home">
        <Header />
        <Heading>
          <Section1>
            <SmallHeading>High Quality Production House📽️</SmallHeading>
            <BigHeading>Let’s Make Great Things Together</BigHeading>
            <ParaHeading>
              Elevate your brand with our expert video production.We turn
              visions into captivating visual stories that leave a lasting
              impression
            </ParaHeading>
            <ButtonStyle>
              {" "}
              <Button onClick={toggleModal}>Lets Explore</Button>
            </ButtonStyle>
          </Section1>
        </Heading>
        {isModalOpen && <VideoPopup onClose={toggleModal} />}
      </BackgroundImage>
    </>
  );
};

export default Index;

import React,{useState} from 'react'
import 
{
    StyledInfo,
    TextHeading,
    TitleHeading,
    MainHeading,
    ParaHeading,
    StyledCard,
    CardData,
    Image,
    MainHeadingCard,
    ParaCardHeading,
    CardDataItems,
    Icons,
    MainHeadingCardItems,
    ParaCardHeadingItems,
    StyledDataItems,                     
} 
from './style';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import AboutVideo from "../AboutVideo";
import {WorkData,WorkData1} from '../../utils/workdata';

const Work = () => {
    const [activeButton, setActiveButton] = useState(0);
    const [counterOn, setCounterOn] = useState(false);
    const handleButtonClick = (index) => {
      setActiveButton(index);
    };
    return (
      <>
        <StyledInfo id="About us">
          <TextHeading>
            <TitleHeading>What is Liam Crest</TitleHeading>
            <MainHeading>
              We Are A Creative Video Production Company
            </MainHeading>
            <ParaHeading>
              We work with clients throughout the entire process to ensure
              satisfaction. We have competitive pricing. We also provide grants
              to subsidize cost for organizations that qualify.
            </ParaHeading>
          </TextHeading>
          <StyledCard>
            {WorkData.map((item) => (
              <CardData key={item.id}>
                <Image src={item.image} />
                <MainHeadingCard>{item.title}</MainHeadingCard>
                <ParaCardHeading>{item.para}</ParaCardHeading>
              </CardData>
            ))}
          </StyledCard>
          <StyledDataItems>
            {WorkData1.map((item) => (
              <CardDataItems key={item.id}>
                <Icons src={item.image1} />
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <MainHeadingCardItems>
                    {counterOn && (
                      <CountUp
                        start={activeButton === item ? 0 : null}
                        end={parseInt(item.number)}
                        duration={2}
                        delay={0}
                      />
                    )}
                    +
                  </MainHeadingCardItems>
                </ScrollTrigger>
                <ParaCardHeadingItems>{item.title1}</ParaCardHeadingItems>
              </CardDataItems>
            ))}
          </StyledDataItems>
            </StyledInfo>
            <AboutVideo />
      </>
    );
}

export default Work;
import React from "react";
import {
  StyledCard,
  StyledContainer,
  StyledH2,
  StyledIcon,
  StyledMain,
  StyledP,
  StyledP1,
  StyledP2,
  StyledP3,
  StyledRow,
  StyledText,
} from "./styles";
import { TiVideo } from "react-icons/ti";
import { RxVideo } from "react-icons/rx";
import { TbMovie } from "react-icons/tb";
import { SiGotomeeting } from "react-icons/si";
import { HiDocumentText } from "react-icons/hi2";
const Card = ({ icon, content, info }) => {
  return (
    <StyledCard>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledH2>{content}</StyledH2>
      <StyledP3>{info}</StyledP3>
    </StyledCard>
  );
};
function ServicesSection() {
  return (
    <StyledMain id="Services">
      <StyledText>
        <StyledP>Our Services</StyledP>
        <StyledP1>Services We Are Providing</StyledP1>
        <StyledP2>
          Transforming ideas into compelling visual stories, we offer
          comprehensive video production and creative strategy services for a
          lasting impact
        </StyledP2>
      </StyledText>
      <StyledContainer>
        <StyledRow>
          <Card
            content="Video Production"
            info=" Deliver engaging training experiences with our expertly produced videos, blending clarity and advanced creativity to enhance learning."
            icon={<TiVideo style={{ width: "100%", height: "100%" }} />}
          ></Card>
          <Card
            content="Training Videos"
            info=" Transform ideas into visually stunning stories with our comprehensive services, blending artistic vision and technical expertise to create captivating content from start to finish."
            icon={<RxVideo style={{ width: "100%", height: "100%" }} />}
          ></Card>
          <Card
            content="Movies"
            info="Experience storytelling at its finest with our film production, where artistic flair and technical excellence converge to craft cinematic experiences."
            icon={<TbMovie style={{ width: "100%", height: "100%" }} />}
          ></Card>
        </StyledRow>
        <StyledRow>
          <Card
            content="Conferences & Meetings"
            info=" Elevate your events with professional video coverage, seamlessly capturing and highlighting key moments for enhanced engagement."
            icon={<SiGotomeeting style={{ width: "100%", height: "100%" }} />}
          ></Card>
          <Card
            content="Documentaries"
            info="Uncover compelling narratives through our thought-provoking documentaries, bringing a cinematic touch to diverse and impactful stories."
            icon={<HiDocumentText style={{ width: "100%", height: "100%" }} />}
          ></Card>
          <Card
            content="Advertising"
            info=" Uncover compelling narratives through our thought-provoking documentaries, bringing a cinematic touch to diverse and impactful stories."
            icon={<TiVideo style={{ width: "100%", height: "100%" }} />}
          ></Card>
        </StyledRow>
      </StyledContainer>
    </StyledMain>
  );
}

export default ServicesSection;

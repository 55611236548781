import React, { useState } from "react";
import {
  StyledDiv,
  StyledIcon,
  StyledImg,
  StyledImg1,
  StyledImgContainer,
  StyledInput,
  StyledMain,
  StyledP3,
  StyledSolutionContainer,
  StyledText,
  Styledtext1,
} from "./styles";
import { StyledP, StyledP1, StyledP2 } from "../AboutSection/styles";
import { FiMinus, FiPlus } from "react-icons/fi";

function VideoSolution() {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleVisibility1 = () => {
    setIsVisible1(!isVisible1);
  };
  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };
  const toggleVisibility3 = () => {
    setIsVisible3(!isVisible3);
  };
  return (
    <StyledMain>
      <StyledImgContainer>
        <StyledImg src="/img101.png" alt="img"></StyledImg>
        <StyledImg1 src="/img.png" alt="img"></StyledImg1>
      </StyledImgContainer>
      <StyledSolutionContainer>
        <StyledP>What we do</StyledP>
        <StyledP1>Video Solution Every Stage</StyledP1>
        <StyledP2>
          Captivate your audience with our video stories, showcasing your
          passion and expertise. From concept to consumer, our creative
          strategies inspire action and redefine outreach
        </StyledP2>
        <StyledDiv>
          <StyledInput>
            <Styledtext1 onClick={toggleVisibility}>
              <StyledText>Pre Production</StyledText>
              <StyledIcon onClick={toggleVisibility}>
                {isVisible ? <FiMinus /> : <FiPlus />}
              </StyledIcon>
            </Styledtext1>
            <StyledP3 isVisible={isVisible}>
              During the pre - production stage, we do a deep dive into your
              company’s history, mission, and vision. We aim to ensure your
              brand, products, or services are connected to the cinematic
              vision. Getting to know you is our initial goal
            </StyledP3>
          </StyledInput>
          <StyledInput>
            <Styledtext1 onClick={toggleVisibility1}>
              <StyledText>Production</StyledText>
              <StyledIcon onClick={toggleVisibility1}>
                {isVisible1 ? <FiMinus /> : <FiPlus />}
              </StyledIcon>
            </Styledtext1>
            <StyledP3 isVisible={isVisible1}>
              Now that we know who you are and what you do, our team goes away
              to their creative corners to pull together ideas that convert your
              messaging from concepts to visual stories
            </StyledP3>
          </StyledInput>
          <StyledInput>
            <Styledtext1 onClick={toggleVisibility2}>
              <StyledText>Post Production</StyledText>
              <StyledIcon onClick={toggleVisibility2}>
                {isVisible2 ? <FiMinus /> : <FiPlus />}
              </StyledIcon>
            </Styledtext1>
            <StyledP3 isVisible={isVisible2}>
              Now that we have all the materials, it is time to create something
              amazing. We finalize all ideas and edit to create your final
              product
            </StyledP3>
          </StyledInput>
          <StyledInput>
            <Styledtext1 onClick={toggleVisibility3}>
              <StyledText>Conversion</StyledText>
              <StyledIcon onClick={toggleVisibility3}>
                {isVisible3 ? <FiMinus /> : <FiPlus />}
              </StyledIcon>
            </Styledtext1>
            <StyledP3 isVisible={isVisible3}>
              After approval of the final product, we deliver all related files
              and data to clients in a timely manner
            </StyledP3>
          </StyledInput>
        </StyledDiv>
      </StyledSolutionContainer>
    </StyledMain>
  );
}

export default VideoSolution;

import HeroSection from "../HeroSection";
import Work from "../Work";
import ServiceSection from "../ServicesSection";
import Production from "../Production";
import Footer from "../Footer";
import Collaborate from "../Collaborate";
import Projects from "../Projects";

import VideoSolution from "../VideoSolution";
import AboutSection from "../AboutSection";
import TestimonialSec from "../TestimonialSec";
function Main() {
  return (
    <>
      <HeroSection />
      <AboutSection />
      <Work />

      <VideoSolution />
      <ServiceSection />
      <Production />
      <Projects />
      <TestimonialSec/>

      <Collaborate />
      <Footer />
    </>
  );
}

export default Main;

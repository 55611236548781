import React,{useState} from 'react'
import 
{
    StyledCollaborate,
    StyledText,
    HeadingTitle,
    HeadingPara,
    StyledInput,
    InputData1,
    InputData2,
    InputData3,
    StyledDisplay,
    StyledSection,
    StyledIcon,
    StyledButton,
    Button
} from "./style"
import axios from "axios";

const Collaborate = () => {
    const initialFormData = {
        website: 'Production',
        name: '',
        email: '',
        message: ''
    };
 const [status, setStatus] = useState(null);
 const [statusColor, setStatusColor] = useState("black");
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
    const resetForm = () => {
      setFormData({
        website: "Production",
        name: "",
        email: "",
        message: "",
      });
    };
     const handleSend = async () => {
       //  alert("sa");
       if (!formData.name || !formData.email || !formData.message) {
         setStatus("Please fill in all fields.");
         setStatusColor("red");
         return;
       }

       if (!validateEmail(formData.email)) {
         setStatus("Please enter a valid email address.");
         setStatusColor("red");
         return;
       }

       try {
         const response = await axios.post(
           "https://lmsbackend.liamcrest.com/api/register_query",
           formData,
           {
             headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
             },
           }
         );

         if (response.status === 200) {
           setStatus("Form submitted successfully!");
           setStatusColor("green");
           resetForm(); // Reset form data after successful submission
         } else {
           setStatus("Form submission failed.");
           setStatusColor("red");
         }
       } catch (error) {
         setStatus("Error sending form data: " + error.message);
         setStatusColor("red");
       }
     };
  return (
    <StyledCollaborate>
      <StyledSection>
        <StyledText>
          <HeadingTitle>Collaborate</HeadingTitle>
          <HeadingPara>Want to work with us? Leave us a message</HeadingPara>
        </StyledText>

        <StyledIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="249"
            height="120"
            viewBox="0 0 249 120"
            fill="none"
            style={{
              transform: "translateY(50%)",
            }}
          >
            <path
              d="M176.477 29.9615C183.917 25.3941 192.301 22.5445 200.706 21.7392C207.871 21.0377 215.314 21.815 222.403 23.961L222.647 21.1138C215.277 18.9857 207.774 18.2453 200.441 18.9465C191.613 19.7994 182.826 22.7824 175.018 27.57C167.209 32.3576 160.583 38.8554 155.829 46.353C154.488 48.4626 153.308 50.642 152.277 52.8762C140.094 56.4917 129.254 63.2022 119.313 70.0178C116.892 71.6712 114.451 73.3929 112.099 75.0522C103.483 81.0998 94.5848 87.347 85.0269 91.9848C74.2433 97.2172 62.1623 100.45 50.0869 101.332C37.6734 102.242 25.6311 100.777 14.2933 96.9662C9.64481 95.3929 5.12804 93.4252 0.850449 91.1142L0.592363 94.1281C4.74419 96.2744 8.9928 98.1072 13.4112 99.5908C20.3168 101.917 27.4905 103.399 34.8213 104.026C39.9029 104.462 45.0757 104.485 50.2969 104.107C62.7103 103.197 75.149 99.8691 86.2536 94.4823C96.0015 89.7488 105.003 83.4404 113.694 77.3293C116.046 75.67 118.486 73.9622 120.892 72.3215C130.044 66.054 139.929 59.8912 150.869 56.2532C147.565 64.9522 146.683 74.1104 148.482 82.5048C149.808 88.7323 152.719 94.5917 156.89 99.4539C161.213 104.497 166.611 108.107 172.514 109.886C178.553 111.718 185.274 111.468 191.452 109.227C197.789 106.916 202.922 102.668 205.93 97.2736C209.251 91.3182 210.004 83.9957 208.044 76.6648C206.19 69.7346 201.987 63.2888 196.496 58.9712C191.176 54.7942 184.708 52.0858 177.311 50.9346C170.617 49.8858 163.36 50.2016 156.324 51.8377C156.167 51.8802 156.011 51.9087 155.853 51.9512C156.559 50.5706 157.346 49.2111 158.187 47.884C162.717 40.717 169.037 34.5288 176.477 29.9615ZM156.947 54.5352C163.639 52.9817 170.531 52.6905 176.88 53.6818C183.808 54.7648 189.83 57.2811 194.77 61.1599C199.79 65.1013 203.647 71.0138 205.34 77.3706C207.108 83.9996 206.446 90.5884 203.487 95.9033C198.033 105.705 184.769 110.669 173.303 107.211C167.917 105.589 162.971 102.269 158.99 97.633C155.125 93.1189 152.428 87.6975 151.188 81.9252C149.358 73.4161 150.498 64.0142 154.287 55.2168C155.176 54.9572 156.062 54.7392 156.947 54.5352Z"
              fill="white"
            />
            <path
              d="M248.414 30.3763L221.854 6.13083L221.467 16.8056L240.062 26.9423L221.325 20.7468L221.324 20.757L221.324 20.7447L213.125 23.4484L221.108 26.7308L220.834 34.3142L248.414 30.3763Z"
              fill="white"
            />
          </svg>
        </StyledIcon>

        <StyledInput>
          <StyledDisplay>
            <InputData1
              type="text"
              id="name"
              placeholder="Enter your name"
              onChange={handleChange}
              value={formData.name}
            />
            <InputData2
              type="email"
              id="email"
              placeholder="Enter your email"
              onChange={handleChange}
              value={formData.email}
            />
          </StyledDisplay>
          <InputData3
            rows={4}
            id="message"
            placeholder="Enter a topic"
            onChange={handleChange}
            value={formData.message}
          />
          {status && (
            <h4
              style={{
                marginTop: "10px",
                color: statusColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {status}
            </h4>
          )}
          <StyledButton>
            <Button onClick={handleSend}>Let’s get started 👉🏻</Button>
          </StyledButton>
        </StyledInput>
      </StyledSection>
    </StyledCollaborate>
  );
}

export default Collaborate
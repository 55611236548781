export const WorkData = [
    { 
      id: 1,
      image: '../img11.png',  
      title: 'Who we are?' ,
      para:'Transforming ideas into reality through video production, web development, compliance training, and graphic design. Experience the convenience of a full-service solution as our dedicated team of videographers, artists, producers, developers, and editors collaborate to bring your vision to life.'
    },
    { 
      id: 2,
      image: '../img12.png',  
      title: 'How we work?',
      para:'In pre-production, we delve into your history and vision, aligning them with our cinematic approach. Understanding your essence is our priority. Our creative team then crafts compelling visual stories that resonate with your brand. Efficiently delivering the final product, we ensure your vision becomes a captivating reality.' 
    },  
];
export const WorkData1 = [
    { 
      id: 1,
      image1: '../img21.png',  
      number: '500' ,
      title1:'Videos'
    },
    { 
      id: 2,
      image1: '../img22.png',  
      number: '3756',
      title1:'Users' 
    },  
    { 
        id: 3,
        image1: '../img23.png',  
        number: '300',
        title1:'Trainings' 
      },  
      { 
        id: 4,
        image1: '../img24.png',  
        number: '300',
        title1:'Meetings' 
      },  
];
import styled from "styled-components";


export const StyledInfo=styled.div`
display:flex;
align-items: center;
flex-direction: column;
height:auto;
width:100%;
background: #0A1219;
justify-content: center;
@media only screen and (min-width:1281px) and (max-width:1536px){
    height:auto;
}
/* @media only screen and (min-width:1081px) and (max-width:1280px){
    height:100vh;
}*/
@media only screen and (min-width:811px) and (max-width:1080px){
    height:auto;
} 
@media only screen and (min-width:320px) and (max-width:640px){
    height:auto;
    justify-content:center;
} 
`;
export const TextHeading=styled.div`
display:flex;
align-items: center;
flex-direction: column;
align-items: center;

`;
export const TitleHeading=styled.p`
color: var(--G2, #1EBB90);
text-align: center;
font-family: "Montserrat";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
transform: translateY(30px);

`;
export const MainHeading=styled.h2`
color: #FFF;
text-align: center;
font-family: "Montserrat";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
transform: translateY(-5px);
@media only screen and (min-width:811px) and (max-width:1080px){
    font-size: 24px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    font-size: 24px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    font-size: 24px;
}
`;
export const ParaHeading=styled.p`
color: #E9EEF1;
text-align: center;
font-family: "Montserrat";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
width:60%;
transform: translateY(-30px);
`;
export const StyledCard=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction:row;
gap: 16px;
width:92%;
@media only screen and (min-width:641px) and (max-width:810px){
    flex-direction:column;
    gap: 18px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    flex-direction:column;
    gap: 18px;
}
`;
export const CardData=styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
border-radius: 24px;

background: linear-gradient(92deg, rgba(53, 97, 142, 0.10) 0%, rgba(30, 187, 144, 0.10) 100%);
&:hover{
border:1px solid  #1EBB90;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
transition:0.6s ease-in;
}
`;
export const Image=styled.img`
border-radius: 16px;
width:100%;


`;
export const MainHeadingCard=styled.div`
color: #FFF;
text-align: center;
font-family: "Montserrat";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
@media only screen and(min-width:811px) and (max-width:1080px){
    font-size: 20px;
}
`;
export const ParaCardHeading=styled.div`
color: #E9EEF1;
font-family: "Montserrat";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
@media only screen and(min-width:811px) and (max-width:1080px){
    font-size: 14px;
}
@media only screen and(min-width:641px) and (max-width:810px){
    font-size: 14px;
}
@media only screen and(min-width:320px) and (max-width:640px){
    font-size: 14px;
}
`;
export const StyledDataItems=styled.div`
display:flex;
flex-direction: row;
padding: 48px 128px;
justify-content: space-between;
align-items: center;
width:74%;
border-radius: 24px;
background: linear-gradient(92deg, rgba(53, 97, 142, 0.10) -1.5%, rgba(30, 187, 144, 0.10) 96.99%);
margin-top:1%;
&:hover{
border:.3px solid  #1EBB90;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
transition:0.2s ease-in;
}
@media only screen and (min-width:1881px) and (max-width:2080px){
width:78%;
}
@media only screen and (min-width:1680px) and (max-width:1880px){
width:76%;
}
@media only screen and (min-width:1191px) and (max-width:1280px){
    width:71%; 
}
@media only screen and (min-width:1081px) and (max-width:1190px){
    width:69%; 
}
@media only screen and (min-width:972px) and (max-width:1080px){
    width:66%; 
}
@media only screen and (min-width:811px) and (max-width:971px){
   
    width: 746px;
padding: 48px 24px;
}
@media only screen and (min-width:710px) and (max-width:810px){
    transform: translateY(10px);
    width: 632px;
    padding: 48px 24px;
}
@media only screen and (min-width:641px) and (max-width:709px){
transform: translateY(10px);
display: flex;
width: 574px;
padding: 48px 24px;
justify-content: space-between;
align-items: center;
}
@media only screen and (min-width:491px) and (max-width:640px){
    display: grid;
width: 82%;
padding: 48px 24px;
grid-template-columns: auto auto;
align-items: center;
gap: 22px;
transform: translateY(10px);
}
@media only screen and (min-width:320px) and (max-width:490px){
    display: grid;
width: 78%;
padding: 48px 24px;
grid-template-columns: auto auto;
align-items: center;
gap: 22px;
transform: translateY(10px);
}
`;
export const CardDataItems=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction: column;

`;
export const Icons=styled.img`
width: 64px;
height: 64px;
`;
export const MainHeadingCardItems=styled.h2`
color: #FFF;
text-align: center;
font-family: "Montserrat";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;
export const ParaCardHeadingItems=styled.p`
color: #FFF;
text-align: center;
font-family: "Montserrat";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:-2%;
`;
   
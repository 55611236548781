import styled from 'styled-components';

export const StyledInfo=styled.div`
width: 100%;
  background: #0A1219;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  overflow: hidden;
  background-size: 100%;
  justify-content: center;
  flex-direction:column;
 
`;
export const StyledText=styled.div`
display:flex;
align-items:center;
justify-content: center;
flex-direction: column;
margin-top:4%;
`;
export const HeadingTitle=styled.h1`
color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 78px; /* 216.667% */
letter-spacing: -1px;
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 23px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 23px;
}
`;
export const StyledMainContainer=styled.div`
display:flex;
align-items: center;
justify-content: center;
flex-direction: row;
gap:10px;
width:100%;
overflow:hidden;

@media only screen and (min-width:1281px) and (max-width:1536px){

}
`;
export const Swiper=styled.div`

`;
export const StyledCard = styled.div`
  display: flex;
  padding: 32px;
  height: 400px;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 16px;
  background: #0d151f;
  box-shadow: 0px 23px 44px -31.469px rgba(18, 20, 29, 0.09);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    padding: 28px;
    padding-bottom: 80px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 600px;
  }
`;
export const StyledA=styled.div`
display:flex;
align-items: center;
justify-content:space-between;
width:95%;
`;
export const StyledAllItems=styled.div`
margin-left: 4%;
`;
export const StyledTextCard=styled.h3`
color: #EBEDF4;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Montserrat";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 29px;
@media only screen and (min-width:1081px) and (max-width:1280px){
  font-size:30px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 26px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 24px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 23px;
}
`;
export const StyledIcons=styled.div`

margin-top:-4%;
`;
export const StyledPara=styled.p`
color: #CBCDDA;
font-family: "Montserrat";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align:left;
width:89%;
margin-top:7%;
@media only screen and (min-width:1081px) and (max-width:1280px){
  font-size:14px;
  width:100%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size:14px;
  width:100%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size:12px;
  width:100%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size:12px;
  width:100%;
}
`;
export const StyledItemText=styled.div`
display:flex;
margin-top:8%;
justify-content: space-between;
width:90%;
`;
export const TitleName=styled.h4`
color: #FFF;
font-family: "Montserrat";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 125% */
span{
    color: rgba(255, 255, 255, 0.50);
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 16px;
  span{
    font-size:16px;
  }
}
@media only screen and (min-width:641px) and (max-width:810px){
  font-size:14px;
  span{
    font-size:14px;
  }
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size:12px;
  span{
    font-size:12px;
  }
}
`;
export const LogoImage=styled.img`
width: 48px;
height: 48px;
flex-shrink: 0;
border-radius: 48px;
margin-top:3%;
@media only screen and (min-width:811px) and (max-width:1080px){
  width:46px;
  height:46px;
}
@media only screen and (min-width:641px) and (max-width:810px){
  width:44px;
  height:44px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  width:42px;
  height:42px;
}
`;

